<template>
  <div class="pages">
    <van-sticky>
      <header class="nav-bar">
        <div @click="newAppBack" class="nav-bar-left"><van-icon name="arrow-left" /></div>
        <div class="nav-bar-title">我创建的运动方案</div>
        <div class="nav-bar-right">
          <div @click="createUserPlan" class="create-btn row-center-center bold">新建运动规划</div>
        </div>
      </header>
      <div class="user-search row-between-center">
        <div class="row-center-center search-left">
          <form style="width: 100%;" action="/">
            <van-search @search="onSearch" class="search-input" v-model="phone" clearable placeholder="请输入会员手机号" />
          </form>
        </div>
        <div v-if="isSearch" @click="onCancel" class="search-btn">取消</div>
      </div>
    </van-sticky>
    <p v-if="list.length && !isSearch" class="tip f14">以下列表为你过往运动规划的会员，若其他教练若重新定制运动规划则会覆盖你的过去的方案</p>
    <van-list class="list" v-if="list.length" v-model="loading" :finished="finished" @load="getList" :immediate-check="false" :offset="50" finished-text="">
      <div v-for="item,index in list" :key="index" class="item row-start-center">
        <div @click="goUserPlanDetail(item)" class="row-between-center item-left-box">

          <div class="item-left">
            <div class="row-start-center user-info">
              <div class="user-header girl"><img :src="item.user.headImg" alt=""></div>
              <div class="user-name">
                <div class="bold">{{ item.user.nickName }}</div>
                <p>{{ item.user.phone }}</p>
              </div>
            </div>

            <template v-if="item.teachingTemplate">
              <div class="row-between-center">
                <h3 class="f14">{{ item.teachingTemplate.months }}个月 · 每周{{ item.teachingTemplate.weekCount }}次</h3>
              </div>
              <p class="color3">
                <span class="health-tag" v-for="tag in item.teachingTemplate.labelList" :key="tag">{{ tag }}</span>
              </p>
              <div class="create-date row-start-end">
                <img :src="item.teachingTemplate.createTeacherPic" alt="">
                <span>{{ item.teachingTemplate.createTeacherName }} 创建于 {{ item.teachingTemplate.startTime }} (方案时效{{ item.teachingTemplate.useDays }}天)</span>
              </div>
            </template>
            <template v-else>
              <p class="f14">暂无运动方案</p>
            </template>
          </div>
        </div>
      </div>
    </van-list>

    <div class="empty row-center-center" v-else>
      <van-empty image-size="21vw" image="https://img.chaolu.com.cn/PAD/icon/empty.png">
        <div v-if="isSearch" class="create-btn2">
          <p>没有找到你想要的结果</p>
          <div>请检查输入的手机号是否正确</div>
        </div>
        <div v-else class="create-btn2">
          <p>还没有为会员做过运动方案哦</p>
        </div>
      </van-empty>
    </div>


    <!-- <van-popup v-model="show" :style="{ padding: '64px',background: 'transparent' }">
            <div class="popup-box">
                <h4>扫码购买最新优惠卡券</h4>
                <img :src="'data:image/png;base64,' + qrCode" alt="">
            </div>
            <img @click="show = false" class="result-close"
                 src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/double11-2021/result-close.png"
                 alt="">
        </van-popup> -->

  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, appOpenWeb } from '@/lib/appMethod'
import { userPlanList, goUserPlanDetail, getAuthorization } from './ipadMethod'
import R from '@/lib/regular'

export default {
  mixins: [userMixin],
  components: {

  },
  data() {
    return {
      list: [], // 历史
      authorization: '',
      current: 1,
      loading: false,
      finished: false,

      isSearch: false,
      phone: '',
    }
  },

  async created() {
    // 初始化
    await this.$getAllInfo(['teacherUserId', 'teacherToken'])
    initBack()

    this.authorization = await getAuthorization()
    this.getList()
  },

  methods: {
    newAppBack,

    getList() {

      this.$axios.post(`${this.baseURLApp}/ipad/user/teaching/template/listTeacherCreatedUser`, {
        current: this.current,
        size: 10,
        phone: this.phone,
        authorization: this.authorization || 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJsenE3MzciLCJzY29wZSI6WyJhbGwiXSwiYXBwSWQiOjQsImV4cCI6MTY5NDExMTU5NCwidXNlcklkIjoxMDg1LCJhdXRob3JpdGllcyI6WyJbXSJdLCJqdGkiOiJkNDgwMTVmNC0yOTExLTRkZWQtOGRlYi01NjVmYzkzZWY5MjEiLCJjbGllbnRfaWQiOiI0IiwidXNlcm5hbWUiOiJsenE3MzcifQ.bIMJVOc6--FyR3DTcmi1OkdBEPpLDsbfRzdP_5hPaMI'
      }).then(res => {
        if (!res.data) {
          this.list = []
        }

        this.list = this.list.concat(res.data)

        this.loading = false
        this.current++
        if (res.data.list < 10) {
          this.finished = true
        }
      }).catch(r => {
        this.finished = true
      })
    },

    goAccess() {
      appOpenWeb('', `${window.location.origin}/#/talk-order/questionnaire`)
    },
    goCase(item) {
      appOpenWeb('', `${window.location.origin}/#/talk-order/case?userTeachingTemplateId=${item.userTeachingTemplateId}`)
    },
    onSearch(v) {
      const p = R.removeAllSpace(this.phone)
      console.log(p);
      if (!p) return

      this.finished = false
      this.list = []
      this.current = 1
      this.getList()
      this.isSearch = true
    },
    onCancel() {
      this.finished = false
      this.list = []
      this.phone = ''
      this.isSearch = false
      this.current = 1
      this.getList()
    },
    createUserPlan() {
      userPlanList()
    },
    goUserPlanDetail(item) {
      if (!item.teachingTemplate) return
      goUserPlanDetail(item.teachingTemplate.userTeachingTemplateId)
    }
  },

}
</script>
<style scoped lang="less">
.pages {
  background: #F5F6FA;
  min-height: 100vh;
}

.empty {
  min-height: calc(70vh);
}

.nav-bar {
  width: 750px;
  background: white;
  display: flex;
  align-items: center;
  padding: calc(15px + env(safe-area-inset-top)) 20px 15px;
  box-sizing: border-box;

  .nav-bar-left {
    width: 120px;
    font-size: 24px;
  }

  .nav-bar-title {
    color: #000000;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    width: 470px;
  }

  .nav-bar-right {
    width: 120px;
  }
}

.tip {
  padding: 20px 20px 0;
}

.list {
  flex-grow: 1;
  overflow-y: auto;
  box-sizing: border-box;
  margin-top: 20px;
  padding: 0 20px 30px;

  .item {
    padding: 20px;
    background: white;
    border-radius: 10px;
    font-size: 14px;

    &+.item {
      margin-top: 20px;
    }

    img {
      width: 117px;
      height: 117px;
      border-radius: 10px;
        object-fit: cover;
    }

    .item-left-box {
      flex-grow: 1;
    }

    .item-left {
      flex-grow: 1;
      padding-right: 10px;
      box-sizing: border-box;

      .user-info {
        margin-bottom: 20px;

        .user-header {
          width: 55px;
          border-radius: 10px;
          border: 3px solid #FAFAFB;
          height: 55px;
          margin-right: 14px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
          }

          &:after {
            content: '';
            width: 18px;
            height: 18px;
            position: absolute;
            right: 0;
            bottom: 0;
            background-size: 100% 100%;
            background-position: center center;
            background-repeat: no-repeat;
          }

          &.girl:after {
            background-image: url("https://img.chaolu.com.cn/PAD/icon/girl.png");
          }

          &.boy:after {
            background-image: url("https://img.chaolu.com.cn/PAD/icon/boy.png");
          }
        }

        .user-name {
          div {
            line-height: 16px;
            font-size: 16px;
          }

          p {
            line-height: 14px;
            font-size: 14px;
            color: #6C727A;
            margin-top: 14px;
            font-weight: normal;
          }
        }
      }

      &>p {
        margin-top: 18px;
        flex-wrap: wrap;

        .health-tag {
          height: 27px;
          width: fit-content;
          padding: 0 8px;
          background: rgba(252, 44, 17, 0.05);
          border-radius: 4px;
          font-size: 15px;
          color: #FC2C11;
          font-weight: 500;
          margin-bottom: 8px;
          flex-shrink: 0;
          margin-right: 8px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          &.gray {
            color: #242831;
            background: #F5F6FA;
          }
        }
      }

      &>div>h3 {
        color: #242831;
        font-size: 16px;
        line-height: 16px;
      }

    }

    .share-btn {
      height: 29px;
      border-radius: 4px;

      color: #FFFFFF;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
      background: url("https://img.chaolu.com.cn/PAD/icon/wechat_white.png") no-repeat 9px center #07C160;
      background-size: 18px 18px;
      padding-left: 30px;
      padding-right: 9px;
      box-sizing: border-box;
    }

    &+.item {
      //margin-top: 12px;
    }
  }

  .create-date {
    margin-top: 8px;

    img {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 6px;
    }

    span {

      line-height: 14px;
      color: #242831;
      font-size: 14px;
    }
  }
}



.create-btn {
  width: 113px;
  height: 39px;
  background: #FFDE00;
  border-radius: 6px;
  font-size: 14px;
  color: #242831;
}

.create-btn2 {
  text-align: center;

  p {
    font-size: 18px;
    line-height: 18px;
    color: #3C454E;
  }

  div {
    margin-top: 12px;
    font-size: 14px;
    line-height: 14px;
    color: #9AA1A9;
  }
}

.bold {
  font-weight: bold;
}

.separate {
  font-size: 14px;
  line-height: 14px;
  color: #9AA1A9;
  margin: 58px 0 28px;
  text-align: center;
}

.user-search {
  border-top: 1px solid #EEEEEE;
  background-color: white;
  padding: 20px;

  .search-left {
    box-sizing: border-box;
    width: 100%;
    height: 47px;
    background: #FFFFFF;
    border-radius: 23px;
    border: 1px solid #F1F1F5;
    overflow: hidden;
    font-size: 16px;
    //padding: 0 16px 0 22px;
    font-weight: 600;

    .van-field {
      padding-right: 0;
    }

    .search-input {
      background-color: white;
      width: 100%;
      padding: 0 16px 0 8px;

      .van-search__content {
        background-color: white;
      }
    }
  }

  .search-btn {
    flex-shrink: 0;
    font-weight: 500;
    font-size: 17px;
    color: #267DFF;
    padding: 0 20px;
  }
}</style >
